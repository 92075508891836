import React from "react";

import { Button, ButtonVariant } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { IModal } from "../../redux/types/app";

import styles from "./AttributionWindowModal.module.scss";

type AttributionWindowModalProps = IModal;

export const AttributionWindowModal: React.FC<AttributionWindowModalProps> = ({ visible, toggle }) => {
  const handleClose = () => toggle();
  const text =
    "Attribution window is a time period in which an ad gets credit for bringing a purchase. When you select, for example, a 7-day attribution window it means that when a customer clicks on an ad and makes a purchase within that time 7-day timeframe, the ad receives the credit for the purchase. The same principle applies to longer attribution windows such as the 14-day and 28-day that ecomtrack offers. This gives our users freedom to choose what attribution window works best for their business.";

  return (
    <Modal visible={visible} toggle={toggle} animation="fadeIn" fitContent className={styles.attributionWindowModal}>
      <h3 className={styles.header}>What is attribution window?</h3>
      <p className={styles.text}>{text}</p>
      <div className={styles.button}>
        <Button variant={ButtonVariant.PRIMARY} label="Okay" onClick={handleClose} />
      </div>
    </Modal>
  );
};
