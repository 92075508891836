import React from "react";

import { Icon } from "../../../components/Icon/Icon";
import { useGetCurrencyTypeCellValue } from "../../../hooks/formatters/useGetCurrencyTypeCellValue";
import { ExtendedCampaign } from "../../../redux/types";
import { colorizeText } from "../../../utils/formatters/colorizeText";
import { getRoasMultiple } from "../../../utils/formatters/currencyFormatters";
import { getFormattedCellValue } from "../../../utils/formatters/stringFormatters";

import styles from "./CampaignItem.module.scss";

interface FormattedCellProps {
  campaign: ExtendedCampaign;
  accessor: keyof ExtendedCampaign;
  subRowArray: ExtendedCampaign[];
}

export const FormattedCell: React.FC<FormattedCellProps> = ({ campaign, accessor, subRowArray }) => {
  const getCurrencyTypeCellValue = useGetCurrencyTypeCellValue();
  const cellValue = campaign[accessor];
  let formattedCellValue: string = cellValue ?? "-";
  let formattedCell = <p>{formattedCellValue}</p>;

  //CURRENCY
  if (["sumPurchasesValue", "sumFbOmniPurchaseValue", "sumFbSpend"].includes(accessor)) {
    formattedCellValue = getFormattedCellValue(cellValue, getCurrencyTypeCellValue);
  }
  //ROAS
  if (accessor === "reportedVsRevenue") {
    formattedCellValue = getFormattedCellValue(cellValue, getCurrencyTypeCellValue);

    const classN = colorizeText(cellValue, accessor);
    formattedCell = (
      <div className={styles.chevronContainer}>
        <p className={styles[classN]}>{formattedCellValue}</p>
        {subRowArray.length > 0 && <Icon name="chevron" rotation="0" width="14px" />}
      </div>
    );

    return formattedCell;
  }
  if (accessor === "roas") {
    formattedCellValue = getFormattedCellValue(cellValue, getRoasMultiple);
    const classN = colorizeText(cellValue, accessor);
    formattedCell = <p className={styles[classN]}>{formattedCellValue}</p>;
    return formattedCell;
  }
  if (accessor === "roasFb") {
    formattedCellValue = getFormattedCellValue(cellValue, getRoasMultiple);
  }

  if (accessor === "src") {
    formattedCell = <div className={styles.iconContainer}>{cellValue}</div>;
    return formattedCell;
  }
  formattedCell = <p>{formattedCellValue}</p>;

  return formattedCell;
};
