import React from "react";

import { Icon } from "../components/Icon/Icon";
import {
  AdProviderEnum,
  IAggregatedCampaigns,
  IAggregatedFbAd,
  IAggregatedFbAdset,
  IAggregatedFbCampaign,
  IAggregatedGoogleAd,
  IAggregatedGoogleAdGroup,
  IAggregatedGoogleCampaign,
} from "../redux/types";

export const transformCampaignsData = (campaigns: IAggregatedCampaigns): any[] => {
  const { organic, facebook, google, tiktok } = campaigns;
  const rows: any[] = [];

  const addOrganicRows = () => {
    const organicRow = {
      ...organic,
      src: <Icon name="organic" />,
      name: "Organic Search",
      nameEnum: AdProviderEnum.ORGANIC,
      sumFbSpend: null,
      sumFbOmniPurchase: null,
      sumFbOmniPurchaseValue: null,
      sumFbClicks: null,
      roas: null,
      roasFb: null,
      reportedVsRevenue: null,
    };
    rows.push(organicRow);
  };

  const addFacebookRows = () => {
    const facebookRows = {
      ...facebook,
      name: "Facebook Ads",
      nameEnum: AdProviderEnum.FACEBOOK,
      roas: getRoas(facebook.sumPurchasesValue, facebook.sumFbSpend),
      roasFb: getRoas(facebook.sumFbOmniPurchaseValue, facebook.sumFbSpend),
      reportedVsRevenue: getRevenueDifference(facebook.sumPurchasesValue, facebook.sumFbOmniPurchaseValue),
      src: <Icon name={"facebook" /*row.src*/} />,
      subRows: getSubRowsObject(facebook.campaigns, AdProviderEnum.FACEBOOK),
    };
    rows.push(facebookRows);
  };

  const addGoogleRows = () => {
    const googleRows = {
      src: <Icon name="ico-googleads-big" width="30" />,
      name: "Google Ads",
      nameEnum: AdProviderEnum.GOOGLE,
      roas: getRoas(google.sumPurchasesValue, google.sumGoogleSpent),
      roasFb: getRoas(google.sumGooglePurchasesValue, google.sumGoogleSpent),
      reportedVsRevenue: getRevenueDifference(google.sumPurchasesValue, google.sumGooglePurchasesValue),
      sumFbClicks: google.sumGoogleClicks,
      sumFbOmniPurchase: google.sumGooglePurchases,
      sumFbOmniPurchaseValue: google.sumGooglePurchasesValue,
      sumFbSpend: google.sumGoogleSpent,
      sumPurchases: google.sumPurchases,
      sumPurchasesValue: google.sumPurchasesValue,
      subRows: getSubRowsObject(google.campaigns, AdProviderEnum.GOOGLE),
    };
    rows.push(googleRows);
  };

  const addTiktokRows = () => {
    const tiktokRows = {
      ...tiktok,
      src: <Icon name="ico-tiktok-big" width="30" />,
      name: "TikTok Ads",
      nameEnum: AdProviderEnum.TIKTOK,
      roas: getRoas(tiktok.sumPurchasesValue, tiktok.sumFbSpend),
      roasFb: getRoas(tiktok.sumFbOmniPurchaseValue, tiktok.sumFbSpend),
      reportedVsRevenue: getRevenueDifference(tiktok.sumPurchasesValue, tiktok.sumFbOmniPurchaseValue),
      subRows: getSubRowsObject(tiktok.ads, AdProviderEnum.TIKTOK),
    };

    rows.push(tiktokRows);
  };

  for (const campaignName in campaigns) {
    if (campaignName === AdProviderEnum.ORGANIC) {
      addOrganicRows();
    }
    if (campaignName === AdProviderEnum.FACEBOOK) {
      addFacebookRows();
    }
    if (campaignName === AdProviderEnum.GOOGLE) {
      addGoogleRows();
    }
    if (campaignName === AdProviderEnum.TIKTOK) {
      addTiktokRows();
    }
  }

  return rows;
};

const getSubRowsObject = (firstLevelSubRowArray: any[], nameEnum: AdProviderEnum) => {
  let subRowsObject: any;

  if (nameEnum === AdProviderEnum.FACEBOOK) {
    subRowsObject = firstLevelSubRowArray.map((row: IAggregatedFbCampaign) => ({
      ...row,
      name: row.name || row.facebookId,
      src: <Icon name={"facebook" /*row.src*/} />,
      roas: getRoas(row.sumPurchasesValue, row.sumFbSpend),
      roasFb: getRoas(row.sumFbOmniPurchaseValue, row.sumFbSpend),
      reportedVsRevenue: getRevenueDifference(row.sumPurchasesValue, row.sumFbOmniPurchaseValue),
      subRows: row.adsets?.map((adset: IAggregatedFbAdset) => ({
        ...adset,
        name: adset.name || adset.id,
        roas: getRoas(adset.sumPurchasesValue, adset.sumFbSpend),
        roasFb: getRoas(adset.sumFbOmniPurchaseValue, adset.sumFbSpend),
        reportedVsRevenue: getRevenueDifference(adset.sumPurchasesValue, adset.sumFbOmniPurchaseValue),
        subRows: adset.ads?.map((ad: IAggregatedFbAd) => ({
          ...ad,
          src: <Icon name={"productSample"} />,
          roas: getRoas(ad.purchasesValue, ad.fbSpend),
          roasFb: getRoas(ad.fbOmniPurchaseValue, ad.fbSpend),
          reportedVsRevenue: getRevenueDifference(ad.purchasesValue, ad.fbOmniPurchaseValue),
          name: ad.name || ad.facebookId,
          sumPurchases: ad.purchases,
          sumPurchasesValue: ad.purchasesValue,
          sumFbSpend: ad.fbSpend,
          sumFbOmniPurchase: ad.fbOmniPurchase,
          sumFbOmniPurchaseValue: ad.fbOmniPurchaseValue,
          sumFbClicks: ad.fbClicks,
        })),
      })),
    }));
  }

  if (nameEnum === AdProviderEnum.GOOGLE) {
    subRowsObject = firstLevelSubRowArray.map((campaign: IAggregatedGoogleCampaign) => ({
      ...campaign,
      name: campaign.name || campaign.googleCampaignId,
      src: <Icon name={"productSample"} />,
      roas: getRoas(campaign.sumPurchasesValue, campaign.sumGoogleSpent),
      roasFb: getRoas(campaign.sumGooglePurchasesValue, campaign.sumGoogleSpent),
      reportedVsRevenue: getRevenueDifference(campaign.sumPurchasesValue, campaign.sumGooglePurchasesValue),
      sumFbClicks: campaign.sumGoogleClicks,
      sumFbOmniPurchase: campaign.sumGooglePurchases,
      sumFbOmniPurchaseValue: campaign.sumGooglePurchasesValue,
      sumFbSpend: campaign.sumGoogleSpent,
      subRows: campaign.adGroups?.map((adGroup: IAggregatedGoogleAdGroup) => ({
        ...adGroup,
        name: adGroup.name || adGroup.googleAdGroupId,
        roas: getRoas(adGroup.sumPurchasesValue, adGroup.sumGoogleSpent),
        roasFb: getRoas(adGroup.sumGooglePurchasesValue, adGroup.sumGoogleSpent),
        reportedVsRevenue: getRevenueDifference(adGroup.sumPurchasesValue, adGroup.sumGooglePurchasesValue),
        sumFbClicks: adGroup.sumGoogleClicks,
        sumFbOmniPurchase: adGroup.sumGooglePurchases,
        sumFbOmniPurchaseValue: adGroup.sumGooglePurchasesValue,
        sumFbSpend: adGroup.sumGoogleSpent,
        subRows: adGroup.ads?.map((ad: IAggregatedGoogleAd) => ({
          ...ad,
          src: <Icon name={"productSample"} />,
          roas: getRoas(ad.sumPurchasesValue, ad.sumGoogleSpent),
          roasFb: getRoas(ad.sumGooglePurchasesValue, ad.sumGoogleSpent),
          reportedVsRevenue: getRevenueDifference(ad.sumPurchasesValue, ad.sumGooglePurchasesValue),
          name: ad.name || ad.googleAdId,
          sumPurchases: ad.sumPurchases,
          sumPurchasesValue: ad.sumPurchasesValue,
          sumFbSpend: ad.sumGoogleSpent,
          sumFbOmniPurchase: ad.sumGooglePurchases,
          sumFbOmniPurchaseValue: ad.sumGooglePurchasesValue,
          sumFbClicks: ad.sumGoogleClicks,
        })),
      })),
    }));
  }
  if (nameEnum === AdProviderEnum.TIKTOK) {
    subRowsObject = firstLevelSubRowArray.map((ad) => ({
      ...ad,
      name: ad.tiktokId,
      roas: getRoas(ad.sumPurchasesValue, ad.sumFbSpend),
      roasFb: getRoas(ad.sumFbOmniPurchaseValue, ad.sumFbSpend),
      reportedVsRevenue: getRevenueDifference(ad.sumPurchasesValue, ad.sumFbOmniPurchaseValue),
      src: <Icon name="productSample" />,
    }));
  }

  return subRowsObject;
};

export const getRoas = (sumPurchasesValue: number | null, sumProviderSpend: number | null): string | number => {
  if (sumPurchasesValue !== null && sumProviderSpend && sumProviderSpend !== 0) {
    return (sumPurchasesValue / sumProviderSpend) * 100;
  }
  return "-";
};

const getRevenueDifference = (
  sumPurchasesValue: number | null | undefined,
  sumProviderPurchasesValue: number | null | undefined,
): number | string => {
  let revenueDifference: string | number = "-";

  if (typeof sumPurchasesValue === "number" && typeof sumProviderPurchasesValue === "number") {
    revenueDifference = sumPurchasesValue - sumProviderPurchasesValue;
  }

  return revenueDifference;
};
