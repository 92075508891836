import React from "react";
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";

import { useSetFacebookAccessToken } from "../../../../api/facebook";
import { useGetCurrentWorkspaceDetail, useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { Divider } from "../../../../components/Divider/Divider";
import { LoadingSpinner } from "../../../../components/LoadingSpinner/LoadingSpinner";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { Switch } from "../../../../components/Switch/Switch";
import { useGetCurrentWorkspaceId } from "../../../../hooks/workspace/useGetCurrentWorkspaceId";
import { showNotification } from "../../../../redux/reducers/notificationReducer";
import { IntegrationStatus, NotificationSeverity } from "../../../../redux/types";
import { getStatusStripeProperties } from "../../../../utils/getStatusStripeProperties";

import styles from "../IntegrationContent.module.scss";

export const FacebookProvider: React.FC = () => {
  const dispatch = useDispatch();
  const currentId = useGetCurrentWorkspaceId();
  const { data: currentWorkspace } = useGetCurrentWorkspaceDetail();
  const sendToken = useSetFacebookAccessToken();
  const { isFetching: isIntegrationFetching, data: integrationDetails } = useGetIntegrationDetails();
  const isFacebookSwitchLoading = sendToken.isLoading || isIntegrationFetching;
  const urlParameters = integrationDetails?.adProvidersData.facebook.urlParameters;
  const state = integrationDetails?.adProvidersData.facebook.state;
  const adAccountId = integrationDetails?.adProvidersData.facebook.adAccountId;
  const fbUrlParametersProps = getStatusStripeProperties([urlParameters]);
  const fbTrackingParametersProps = getStatusStripeProperties([state], adAccountId);
  const isFacebookSkeletonLoading = sendToken.isLoading || isIntegrationFetching;
  const hasRecentPageViews = urlParameters && urlParameters.status === IntegrationStatus.success;
  const isConnected = state && state.status === IntegrationStatus.success;
  const checkedLabel = hasRecentPageViews ? "Working" : "You must set up URL parameters first";
  const uncheckedLabel = hasRecentPageViews ? "Activate" : "You must set up URL parameters first";

  const disableFacebookIntegration = () => {
    if (urlParameters?.status === IntegrationStatus.success) {
      sendToken.mutate({ workspaceId: currentWorkspace!.id, token: null });
    }
  };

  const responseFacebook = (response: any) => {
    if (urlParameters?.status !== IntegrationStatus.success) {
      return;
    }
    if (!currentWorkspace) {
      dispatch(
        showNotification({
          message: "An error ocurred. Please try logging out and logging back in",
          severity: NotificationSeverity.ERROR,
        }),
      );
      return;
    }
    sendToken.mutate({ workspaceId: currentId, token: response.accessToken });
  };

  return (
    <>
      <CardWithTitle iconName="facebook" title="Add URL Parameters" subtitle="Facebook" videoControl={true}>
        <p className={styles.mainAdText}>
          Place the url parameters in the tracking area of <b>every</b> ad in your ad account. This will place tracking
          on all links, even those in the content of the ad when clicked.
        </p>
        <CodeBox src={`fb_ad_id={{ad.id}}`} />
        <p>(If you already have UTMs or parameters in your tracking area add our url parameter with an “&” sign.)</p>
        <Divider />
        <StatusStripe isLoading={isIntegrationFetching} {...fbUrlParametersProps} />
      </CardWithTitle>
      <CardWithTitle iconName="facebook" title="Tracking" subtitle="Facebook" videoControl={true}>
        <div className={styles.fbTracking}>
          <div className={styles.importer}>
            <div className={styles.main}>
              <div className={styles.title}>Facebook analytics</div>
              <div className={styles.subtitle}>Import campaign insights</div>
            </div>
            <div>
              {isFacebookSwitchLoading ? (
                <LoadingSpinner size={24} />
              ) : (
                <FacebookLogin
                  appId="191055759638799"
                  callback={responseFacebook}
                  scope="ads_read,read_insights,ads_management"
                  render={(renderProps: any) => {
                    return (
                      <Switch
                        onCheck={renderProps.onClick}
                        onUncheck={disableFacebookIntegration}
                        checkedLabel={checkedLabel}
                        uncheckedLabel={uncheckedLabel}
                        checked={isConnected}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <Divider />
        <StatusStripe isLoading={isFacebookSkeletonLoading} {...fbTrackingParametersProps} />
      </CardWithTitle>
    </>
  );
};
