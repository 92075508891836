import { DataContentStatus } from "./app";
import { TableColumn } from "./table";

export interface CampaignsState {
  campaigns: Campaign[];
  extendedCampaigns: ExtendedCampaign[];
  campaignsTableCols: { all: TableColumn[]; temporarilySelected: TableColumn[] };
  dataContentStatus: DataContentStatus;
}

export interface Campaign {
  id: number;
  campaignName: string;
  workspaceId: number;
  purchasesValue: number;
  sumFbClicks: number | null;
  sumFbOmniPurchase: number | null;
  sumFbOmniPurchaseValue: number | null;

  sumPurchases?: number;
  sumPurchasesValue?: number;
  sumFbSpend: number;
  roas?: number;
  roasFb?: number;
  src: any;
  reportedVsRevenue: number;
  campaigns?: Campaign[] | ExtendedCampaign[];
  name: string;
  nameEnum: string;
}

export interface ExtendedCampaign {
  id: number;
  campaignName: string;
  workspaceId: number;
  purchasesValue: number;
  sumFbClicks: number | null;
  sumFbOmniPurchase: number | null;
  sumFbOmniPurchaseValue: number | null;
  sumFbSpend: number;
  sumPurchases?: number;
  sumPurchasesValue?: number;
  roas?: number;
  roasFb?: number;
  src: any;
  reportedVsRevenue: number;
  campaigns?: Campaign[] | ExtendedCampaign[];
  expanded?: boolean;
  lastExpanded?: boolean;
  main?: boolean;
  adsets?: ExtendedCampaign[];
  ads?: ExtendedCampaign[];
  rootId?: number;
  parentId?: number;
  depth: number;
  name: string;
  subRows?: Campaign[] | ExtendedCampaign[];
}

export interface CampaignWithAdSets extends Campaign {
  adsets: string[];
  ads: string[];
  rows: Campaign[];
}

export interface CampaignQuery {
  startDate: number;
  endDate: number;
  workspaceId?: number;
}

export interface IAggregatedFacebookMainTemplate {
  sumPurchases: number;
  sumPurchasesValue: number;
  sumFbSpend: number;
  sumFbOmniPurchase: number;
  sumFbOmniPurchaseValue: number;
  sumFbClicks: number;
}

export interface IAggregatedFacebookSubrowsTemplate extends IAggregatedFacebookMainTemplate {
  name: string;
}

export interface IAggregatedFacebook extends IAggregatedFacebookMainTemplate {
  campaigns: IAggregatedFbCampaign[];
}

export interface IAggregatedFbCampaign extends IAggregatedFacebookSubrowsTemplate {
  id: number;
  workspaceId: number;
  adsets: IAggregatedFbAdset[];
  facebookId: string;
}

export interface IAggregatedFbAdset extends IAggregatedFacebookSubrowsTemplate {
  id: string;
  ads: IAggregatedFbAd[];
}

export interface IAggregatedFbAd {
  id: string;
  name: string;
  facebookId: string;
  purchases: number;
  purchasesValue: number;
  fbSpend: number;
  fbOmniPurchase: number;
  fbOmniPurchaseValue: number;
  fbClicks: number;
}

export interface IAggregatedCampaigns extends Record<string, unknown> {
  organic: IAggregatedOrganic;
  facebook: IAggregatedFacebook;
  google: IAggregatedGoogle;
  tiktok: IAggregatedTikTok;
  dataContentStatus: DataContentStatus;
}
export interface IAggregatedCampaign {
  sumPurchases: number | null;
  sumPurchasesValue: number | null;
  sumFbSpend: number | null;
  sumFbOmniPurchase: number | null;
  sumFbOmniPurchaseValue: number | null;
  sumFbClicks: number | null;
}

export interface IAggregatedOrganic {
  sumPurchases: number;
  sumPurchasesValue: number;
}

export interface IAggregatedTikTok extends IAggregatedCampaign {
  ads: IAggregatedTikTokAd[];
}

export interface IAggregatedTikTokAd extends IAggregatedCampaign {
  tiktokId: string;
  ttAdId: number;
}

export enum CampaignColsEnum {
  ALL = "all",
  TEMPORARILY_SELECTED = "temporarilySelected",
}

export interface ICampaignColsInput {
  cols: TableColumn[];
  type: CampaignColsEnum;
}

export interface IAggregatedGoogleMainTemplate {
  sumPurchases: number;
  sumPurchasesValue: number;
  sumGooglePurchases: number;
  sumGooglePurchasesValue: number;
  sumGoogleSpent: number;
  sumGoogleClicks: number;
}

export interface IAggregatedGoogleSubrowsTemplate extends IAggregatedGoogleMainTemplate {
  id: number;
  name: string;
}

export interface IAggregatedGoogle extends IAggregatedGoogleMainTemplate {
  campaigns: IAggregatedGoogleCampaign[];
}

export interface IAggregatedGoogleCampaign extends IAggregatedGoogleSubrowsTemplate {
  googleCampaignId: string;
  adGroups: IAggregatedGoogleAdGroup[];
}

export interface IAggregatedGoogleAdGroup extends IAggregatedGoogleSubrowsTemplate {
  googleAdGroupId: string;
  ads: IAggregatedGoogleAd[];
}

export interface IAggregatedGoogleAd extends IAggregatedGoogleSubrowsTemplate {
  googleAdId: string;
  gAdId: number;
}
