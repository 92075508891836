import React from "react";
import className from "classnames";
import { motion } from "framer-motion";

import { Button, ButtonSize, ButtonVariant } from "../Button/Button";
import { ButtonSwitchBase } from "./ButtonSwitch";

import styles from "./ButtonSwitch.module.scss";

export interface AttributionButtonProps extends ButtonSwitchBase {
  label: string;
  value: string;
}

export const AttributionButton: React.FC<AttributionButtonProps> = (button) => {
  const appliedVariant = button.selectedButtonValue === button.value ? ButtonVariant.TERCIARY : ButtonVariant.TEXT;
  const isActive = button.selectedButtonValue === button.value;
  const handleClick = () => handleButtonSelect(button.value);

  const handleButtonSelect = (buttonValue: string): void => {
    if (!button.handleOnSelect) {
      return;
    }
    button.handleOnSelect(buttonValue);
  };

  const getAppliedButtonClassName = () => {
    const appliedButtonClassNameArray = [styles.btn];

    if (!isActive) {
      appliedButtonClassNameArray.push(styles.notActive);
    }
    const appliedButtonClassName = className(...appliedButtonClassNameArray);

    return appliedButtonClassName;
  };

  return (
    <Button
      size={ButtonSize.SMALL}
      className={getAppliedButtonClassName()}
      variant={appliedVariant}
      onClick={handleClick}>
      <>
        {isActive && <motion.div className={styles.active} layoutId="active" />}
        {button.label}
      </>
    </Button>
  );
};
