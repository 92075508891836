import React from "react";

import { AttributionSwitch } from "../../components/AttributionSwitch/AttributionSwitch";
import { TableColumnSelect } from "../../components/TableColumnSelect/TableColumnSelect";
import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { useMatchScreen } from "../../hooks/screen/useMatchScreen";
import { DownloadControls } from "./DownloadControls";

import styles from "./CampaignsPage.module.scss";

export const TopSegment: React.FC = () => {
  const { isMobile, isTablet } = useGetBreakpointProperties();
  const isScreenMatch = useMatchScreen(768);

  return (
    <div className={styles.topSegment}>
      <div className={styles.header}>
        <span>Campaign overview</span>
        {isScreenMatch && <DownloadControls />}
      </div>
      <AttributionSwitch isInfoHidden={isMobile || isTablet} />
      {!isScreenMatch && (
        <div className={styles.controlsContainer}>
          <TableColumnSelect />
          <DownloadControls />
        </div>
      )}
    </div>
  );
};
