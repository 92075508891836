import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useModal } from "../../hooks/modal/useModal";
import { AttributionWindowModal } from "../../modals/AttributionWindowModal/AttributionWindowModal";
import { setAttributionType } from "../../redux/reducers/appReducer";
import { getAttribution } from "../../redux/selectors/app";
import { AttributionType } from "../../redux/types";
import { ButtonSwitch } from "../ButtonSwitch/ButtonSwitch";
import { Icon } from "../Icon/Icon";

import styles from "./AttributionSwitch.module.scss";

interface AttributionSwitchProps {
  isInfoHidden?: boolean;
}

export const AttributionSwitch: React.FC<AttributionSwitchProps> = ({ isInfoHidden }) => {
  const dispatch = useDispatch();
  const attributionType = useSelector(getAttribution);
  const { visible, toggle } = useModal();
  const handleOnSelect = (buttonValue: string) => dispatch(setAttributionType(buttonValue as AttributionType));

  const attributionOptions = [
    { value: AttributionType.window7day, label: "7D" },
    { value: AttributionType.window14day, label: "14D" },
    { value: AttributionType.window28day, label: "28D" },
    { value: AttributionType.lastClick, label: "Last Click" },
  ];

  return (
    <>
      <div className={styles.switch}>
        {!isInfoHidden && (
          <div className={styles.align}>
            <Icon name="info_icon" width="14px" onClick={() => toggle()} />
            <p>Attribution window:</p>
          </div>
        )}
        <ButtonSwitch
          buttons={attributionOptions}
          handleOnSelect={handleOnSelect}
          selectedButtonValue={attributionType}
        />
      </div>
      <AttributionWindowModal visible={visible} toggle={toggle} />
    </>
  );
};
