import React from "react";
import { AnimateSharedLayout, motion } from "framer-motion";

import { AttributionButton, AttributionButtonProps } from "./AttributionButton";

import styles from "./ButtonSwitch.module.scss";

export interface ButtonSwitchBase {
  selectedButtonValue?: string;
  handleOnSelect?: (buttonValue: string) => void;
}

interface ButtonSwitchProps extends ButtonSwitchBase {
  buttons: AttributionButtonProps[];
}

export const ButtonSwitch: React.FC<ButtonSwitchProps> = ({ buttons, handleOnSelect, selectedButtonValue }) => {
  return (
    <AnimateSharedLayout>
      <motion.div className={styles.ButtonSwitch}>
        {buttons.map((button) => (
          <AttributionButton
            key={button.label}
            {...button}
            handleOnSelect={handleOnSelect}
            selectedButtonValue={selectedButtonValue}
          />
        ))}
      </motion.div>
    </AnimateSharedLayout>
  );
};
