import React from "react";
import { useSelector } from "react-redux";

import { useGetCampaignsFromCurrentDetails } from "../../../api/views";
import { Table } from "../../../components/Table/Table";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { getCampaigns, getTemporaryCampaignCols } from "../../../redux/selectors/campaigns";
import { TableType } from "../../../redux/types/table";
import { useGetFormattedColumnValue } from "./getFormattedColumnValue";
import { getRowClassName } from "./getRowClassName";

export const CampaignTable: React.FC = () => {
  const { isDesktop, isLargeDesktop } = useGetBreakpointProperties();
  const campaignsData = useSelector(getCampaigns);
  const { isLoading } = useGetCampaignsFromCurrentDetails();
  const temporarySelectedCols = useSelector(getTemporaryCampaignCols);
  const isDataLoading = isLoading || campaignsData.length === 0;
  const filteredColumns = temporarySelectedCols.filter((column) =>
    isDesktop || isLargeDesktop ? true : !column.notOnSmallDisplay,
  );

  return (
    <Table
      cols={filteredColumns}
      data={campaignsData}
      loading={isDataLoading}
      defaultSortBy={{ id: "sumPurchasesValue", desc: true }}
      sortBy={[
        { id: "sumFbClicks", desc: false },
        { id: "sumFbSpend", desc: false },
        { id: "sumPurchases", desc: false },
        { id: "sumFbOmniPurchase", desc: false },
        { id: "sumFbOmniPurchase", desc: false },
        { id: "sumFbOmniPurchaseValue", desc: false },
        { id: "sumPurchasesValue", desc: false },
        { id: "sumFbOmniPurchaseValue", desc: false },
        { id: "roas", desc: false },
        { id: "roasFb", desc: false },
      ]}
      getColumnValue={useGetFormattedColumnValue}
      getRowClass={getRowClassName}
      tableType={TableType.CAMPAIGNS}
    />
  );
};
