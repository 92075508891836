import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateCampaignsCols } from "../../redux/reducers/campaignReducer";
import { CampaignColsEnum, ICampaignColsInput } from "../../redux/types";
import { TableCellAlignment, TableColumn } from "../../redux/types/table";
import { useGetBreakpointProperties } from "../screen/useGetBreakpointProperties";

export const useLoadAllCampaignsColumns = (): void => {
  const { isDesktop, isLargeDesktop, appBreakpoint } = useGetBreakpointProperties();

  const dispatch = useDispatch();

  const getNameColumnWidth = (): number => {
    let nameColumnWidth = 300;
    if (isDesktop) {
      nameColumnWidth = 340;
    }
    if (isLargeDesktop) {
      nameColumnWidth = 380;
    }

    return nameColumnWidth;
  };

  const getStandardColumnWidth = (): number => (isDesktop || isLargeDesktop ? 120 : 90);

  const getSkeletonWidth = (width: number): number => width * 0.7;

  const nameColumnSkeletonWidth = getSkeletonWidth(getNameColumnWidth());

  const standardColumnSkeletonWidth = getSkeletonWidth(getStandardColumnWidth());

  const standardColumnSkeletonHeight = 18;

  const campaignsTableCols: TableColumn[] = [
    {
      name: "Src",
      accessor: "src",
      alignment: TableCellAlignment.CENTER,
      skeleton: { round: true, width: 30, height: 30 },
      width: 54,
      minWidth: 54,
      flexGrow: 0,
    },
    {
      name: "Name",
      accessor: "name",
      alignment: TableCellAlignment.LEFT,
      skeleton: { round: false, width: nameColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      minWidth: getNameColumnWidth(),
      width: getNameColumnWidth(),
      flexGrow: 1,
    },
    {
      name: "Clicks",
      accessor: "sumFbClicks",
      alignment: TableCellAlignment.CENTER,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
    },
    {
      name: "Spend",
      accessor: "sumFbSpend",
      alignment: TableCellAlignment.CENTER,
      currency: true,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
    },
    {
      name: "Purchases",
      accessor: "sumFbOmniPurchase",
      alignment: TableCellAlignment.CENTER,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      notOnSmallDisplay: true,
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
    },
    {
      name: "Purchases",
      accessor: "sumPurchases",
      alignment: TableCellAlignment.CENTER,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      tooltipAccessor: "sumFbOmniPurchase",
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
      isRealColumn: true,
    },
    {
      name: "Revenue",
      accessor: "sumFbOmniPurchaseValue",
      alignment: TableCellAlignment.CENTER,
      currency: true,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      notOnSmallDisplay: true,
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
    },
    {
      name: "Revenue",
      accessor: "sumPurchasesValue",
      alignment: TableCellAlignment.CENTER,
      currency: true,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      tooltipAccessor: "sumFbOmniPurchaseValue",
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
      isRealColumn: true,
    },
    {
      name: "ROAS",
      accessor: "roasFb",
      alignment: TableCellAlignment.CENTER,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      notOnSmallDisplay: true,
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
    },
    {
      name: "ROAS",
      accessor: "roas",
      alignment: TableCellAlignment.CENTER,
      coloredNumber: true,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      tooltipAccessor: "roasFb",
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      flexGrow: 0,
      isRealColumn: true,
    },
    {
      name: "Reported vs Revenue",
      accessor: "reportedVsRevenue",
      alignment: TableCellAlignment.CENTER,
      currency: true,
      coloredNumber: true,
      skeleton: { round: false, width: standardColumnSkeletonWidth, height: standardColumnSkeletonHeight },
      width: getStandardColumnWidth(),
      minWidth: getStandardColumnWidth(),
      maxWidth: getStandardColumnWidth(),
      isEcomtrackVsFbColumn: true,
    },
    // {
    //   name: "Status",
    //   accessor: "reportedVsRevenue",
    //   alignment:TableCellAlignment.CENTER,
    //   currency: true,
    //   coloredNumber: true,
    //   skeleton: { round: false, width: getStandardColumnWidth(), height: standardColumnSkeletonHeight },
    //   width: getStandardColumnWidth,
    //   maxWidth: getStandardColumnWidth,
    // },
  ];

  useEffect(() => {
    const colsInput: ICampaignColsInput = { cols: campaignsTableCols, type: CampaignColsEnum.ALL };

    dispatch(updateCampaignsCols(colsInput));
  }, [appBreakpoint]);
};
